import React from "react";
import './Projects.css'
const P_Thesis = () => {
    return (
        <div className="Project-container-c">
      <div className="Project-container">
        <div className="Project-header">
          Year: 2021-2022<br/>
          Domain: web development, sound processing, data processing, media studies, human-computer interation<br/>
          Thesis Committee: Axel Kilian, Arvind Satyanarayan
        </div>
      <div className="u-img-container-full">
          <video autoPlay loop muted>]
              <source src={ process.env.PUBLIC_URL + '/media/attuning_to_hyperobjects_0.mp4' }
                      type="video/mp4" />
          </video>
      </div>
      <h4>Abstract</h4>
          <p>
              Long-running pandemic without an end in sight,
              climate crisis encroaching on our everyday lives—global crises
              are collective events, but they take on multiple forms and
              scales, leading to radically different experiences for people.
              The inter-scalar, inter-temporal representations gained dire
              urgency due to the crises surfacing simultaneously at a global
              scale. Hyperobject, as defined by ecological philosopher
              Timothy Morton, is the in-experienceable object that is vastly
              distributed in time and space that easily exceeds human’s
              perceptive capability. I interrogate a hypothesis: <i>hyperobjects
              are better heard than seen.</i>
          </p>
          <p>
              The thesis is focused on the critical approach to data
              representation, by bringing forward listening as a primary
              mode of interaction. I present Sonic Hypermirror, a custom
              tool that allows data probing of large-scale audio data based
              on vocal interaction, accompanied by a visual interface that
              utilizes computational tools to assemble a soft, continuous
              semantic space of multiple audio streams. It is an experiment
              to build a data sensorium where the listeners enter into,
              inhabit, and learn from. Through the thesis, I propose the
              system of data representation that is continuous, nonreferential,
              and exploratory; and revisit the affordances of
              architectural space as a data storage and an interactive
              sensorium.
          </p>
          <br/>
      <hr/>
      <div className="u-grey">
          read the full text here: <a href="https://dspace.mit.edu/handle/1721.1/143290" target="_blank" rel="noreferrer">link</a>
      </div>

      </div>
        </div>
    )
}

export default P_Thesis
