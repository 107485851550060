import React, {useEffect} from "react";
import './Projects.css'
const P_Chimera = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="Project-container-c">
        <div className="Project-container">
            <div className="Project-header">
                Year: 2021-2022<br/>
                Role: Co-creator, web design and development<br/>
                Domain: web development, social computing<br/>
                Collaborators: Kwan Queenie Li, Wiehan Jiang, Charles Wu
            </div>
            <div className="u-img-container-full">
                <video autoPlay loop muted>]
                    <source src={ process.env.PUBLIC_URL + '/media/chimera_place_0.mp4' }
                    type="video/mp4" />
                </video>
            </div>
            <p>
            <b>chimera.place</b> is an experiment on alternative means of “reading together.” The pilot circulation took place on the summer of 2021, and further developed into a web environment facilitating the reading circulation on the summer of 2022. Before the mass printings, knowledge circulated in the form of manuscript that went through different hands. With this platform, we wanted to recreate this sense of material exchange on web, an anonymous, indirect connection that you get when you discover notes and doodles left by others in books you borrow from the library. Composed of 7 people per group, we had two groups that had signed up to share their reading materials that they wanted to read with the rest of the team, reading one text per week for a total of 7 weeks. 
            </p>
            <div className="u-img-container-center">
                <img src={ process.env.PUBLIC_URL + '/media/chimera_place_2.jpeg' }/>
            </div>
            <div className="u-img-container-full">
                <video autoPlay loop muted>]
                    <source src={ process.env.PUBLIC_URL + '/media/homeweekly__.mp4' }
                    type="video/mp4" />
                </video>
            </div>
            <p>
            Biology borrowed the term chimera in Greek mythology to describe a single organism composed of cells more than one distinct genotype. Such phenomenon in fact frequently occurs in the nature. In some cases, chimeras are much more identifiable: a fear-provoking fire-breathing monster made of lion, goat and snakes. In other cases, chimera might be hard to identify. For example, one of the twins in the mother's womb might be absorbed by the other, who'll carry on living with two sets of DNA and become a human chimera. However, in its essence, every-body is in fact chimeras, as our body is the result of endosymbiosis in a micro scale, and in a broader scale our culture is the ongoing cross-contamination of differences.
            </p>
            <div className="u-img-container-full">
                <video autoPlay loop muted>]
                    <source src={ process.env.PUBLIC_URL + '/media/archive__.mp4' }
                    type="video/mp4" />
                </video>
            </div>
            {/*<div className="u-img-container-full">*/}
            {/*    <img src ={process.env.PUBLIC_URL + '/media/chimera_place_0.jpeg'} title='chimera.place - Home'/>*/}
            {/*</div>*/}
        </div>
        </div>
    )
}

export default P_Chimera
