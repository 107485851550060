import React from "react"

import "../utilities.css";

const Info = () => {
  return (
    <>
      <div className="Project-container-c">
        <div className="Project-container">
        Kii (Wonki) Kang (b. Seoul, Korea) is an artist, architect, programmer, and “hacker” based in Brooklyn, New York. As a digital artist working with web and sound, his works deal with reassembling virtualities, creating embodied semiotics, and making meanings beyond language. Most recently his work was presented at the 2024 Toronto Biennale of Art — entitled <a href="https://torontobiennial.org/programs/memex-room-reindexing-the-archive/" target="_blank" rel="noreferrer">Memex Room</a>, the project deals with computational “vandalization” of archives using AI tools, as well as building a platform for collecting collective sentiment. As an architect and programmer, he has been partaking as a founding employee at a startup developing expert systems for building housings, democratizing architectural knowledge and transparentizing the environmental impact of the buildings. He holds a dual master's in Architecture Studies and Computer Science from MIT.
        <br/><br/>
          <a className="u-link" href="mailto: wonki.kang.official@gmail.com">
            wonki.kang.official [at] gmail [dot] com
          </a>
        </div>
        {/*<div className="Info-text">*/}
        {/*  <p className="Info-title">EDUCATION:</p>*/}
        {/*  Master of Science in <br />*/}
        {/*  Architectural Studies & Computer Science,<br />*/}
        {/*  Massachusetts Institute of Technology<br />*/}
        {/*  2019-2022<br /><br />*/}
        {/*  Bachelor of Architecture,<br />*/}
        {/*  Seoul National University<br />*/}
        {/*  2011-2016*/}
        {/*</div>*/}

      </div>
    </>
  )
}

export default Info;
