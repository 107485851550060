import React, {useEffect} from "react";
import './Projects.css'

const P_Availabler = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
    <div className="Project-container-c">
    <div className="Project-container">
      <div className="Project-header">
        <p>Ambiguous Surfaces, Inquisitive Bodies</p>
        Year: 2022<br/>
        Domain: urbanism, critical computation, machine learning
      </div>
      <div className="u-img-container u-img-container-full">
        <img src='https://static.wixstatic.com/media/05b268_b1b5f82621604e379aef97145d27da79~mv2.jpg/v1/fill/w_817,h_443,al_c,lg_1,q_85,enc_auto/0036.jpg' alt=''/>
        <p className='u-caption u-textRight'>
          ⓒPhotographer Lee Hyunseok @backstepforward
        </p>
      </div>
      <p>
        <i>Availabler</i> explores the ongoing experiments by the <i>ing-er</i> collective, starting from the 2021 exhibition at Forking Room. The article <b>Ambiguous Surfaces, Inquisitive Bodies</b> is based on a dialogue between Wonki Kang (WK) and Daye Kim (DY) reflecting on past works and future directions.
      </p>
      <br/>
      <p>
        <b>DY:</b>
        <br/><br/>
        Availabler aims to revisit and reconstitute urban spaces through the lens of human bodies and behaviors. The term "availabler" is a neologism denoting tools that facilitate the free use of urban spaces. Our goal was to identify and explore urban elements that people can actively engage with, modify, and utilize. In a small exhibition at our studio based in Seoul, we showcased photographs of availablers found in various locations. In 2021, at Forking Room, we developed a machine to mark availabler boundaries using these photographs as a dataset, seeking new possibilities from the machine's incomplete responses. Recently, we exhibited a video where availablers reacted to audience gestures in real time.

      </p>
      <div className="u-img-container-center">
        <img src='https://static.wixstatic.com/media/05b268_dd79a278e7dc4928a2540122f5ea62ab~mv2.jpg/v1/fill/w_791,h_526,al_c,q_85,enc_auto/05b268_dd79a278e7dc4928a2540122f5ea62ab~mv2.jpg'/>
        <img src='https://static.wixstatic.com/media/05b268_cee8f8e820de4bb482a05ee8cdfa436b~mv2.jpg/v1/fill/w_787,h_525,al_c,q_85,enc_auto/05b268_cee8f8e820de4bb482a05ee8cdfa436b~mv2.jpg' />
        <img src='https://static.wixstatic.com/media/05b268_b72b50263f33488186e3168c3f99b76c~mv2.jpg/v1/fill/w_792,h_528,al_c,q_85,enc_auto/05b268_b72b50263f33488186e3168c3f99b76c~mv2.jpg'/>
        <p className='u-caption u-textCenter'> Availabler (2019) </p>
      </div>
      <p>
        <b>WK:</b>
        <br/><br/>
        A significant aspect of Availabler as a project is returning the sovereignty of shaping the environment back to the human body.
        The exhibition in 2021 was an attempt to embrace emerging natural affordances, contrasting with examples that block unintended affordances.
        (see <a href='https://twitter.com/hostiledesign' target='_blank'>@hostiledesign</a>)
        Objects inherently suggest ways of use to perceptual agents, making the environment a holistic field of possibilities.
        In the 2022 exhibition, you took one step further to present the possibility of subverting the power that designers exercise in the design process,
        or any power that is involved in the whole process of design being delivered to the proximity to human bodies.
        While modern design has loaded our surroundings with industrial products cut to fit the standard body, the experience of witnessing the environment generating and morphing in reaction to the gestures reminds the audience that design is not something that is given, but it can be shaped by the active practice of participation. Furthermore, the blobs reverse-engineered from gestures that a machine learning algorithm is spewing out evoke imagination on objects generated from a continuous interface between materials and bodies that breaks away from conventional tectonics.
      </p>
      <div className="u-img-container-center">
        <img src='https://static.wixstatic.com/media/05b268_966a1fc28d064adba0ea7850432fed0e~mv2.jpg/v1/fill/w_789,h_525,al_c,q_85,enc_auto/05b268_966a1fc28d064adba0ea7850432fed0e~mv2.jpg' />
        <img src='https://static.wixstatic.com/media/05b268_cf9d2c297ed6454994ca021f0d4434b7~mv2.jpg/v1/fill/w_790,h_523,al_c,q_85,enc_auto/05b268_cf9d2c297ed6454994ca021f0d4434b7~mv2.jpg' />
        <img src='https://static.wixstatic.com/media/05b268_0b8080cf015f432a82230324050e16bc~mv2.jpg/v1/fill/w_953,h_522,al_c,lg_1,q_85,enc_auto/05b268_0b8080cf015f432a82230324050e16bc~mv2.jpg' />
        <p className='u-caption u-textCenter'> Availabler (2021) </p>
      </div>
      <p>
        It is worth noting the role of the machine here. Part of DY is melded into the machine. Her keen and sympathetic eyes for observing people using urban furniture of their own accord are embedded in the dataset, thus the way the machine understands “objects on which a person can sit” reflects the habits and desires of the way people find objects to sit on. So now, who is the designer here? Data collector, the users captured in the data, or the machine itself? Availabler is not a design automation project. It is about the structure of communication between the designer and user; it is about the imbalance of power that arises in the design process.
      </p>
      <h3>Becoming Ambiguous</h3>
      <p>
        <b>DY:</b>
        <br/><br/>
        Designing cities involves points that defy logical explanation or control.
        The opacity of the black box in machine learning parallels the logical leaps in translating design intentions into user interactions.
        For those who are not aware of the entire design process, the input and output seem to have a fairly plausible causal relationship. However if you look closely into the cities we live in, there are missing links between design logic and form, between form and its use.

        We view these gaps as opportunities rather than flaws. Our recent exhibition reconstructed the design process by creating forms based on visitor actions. At Forking Room, we reversed the form-to-experience stream by extrapolating forms from user experiences.
      </p>
      <br/>
      <p>
        <b>WK:</b>
        <br/><br/>
        Just like humans, machines' decision also become impossible to fully fathom the process of its decision-making once it exceeds a certain level of complexity. It requires a number of "interrogations" to extract causal relations within the circuitry of millions of wires, so it is crucial to translate the tangled wires into alternative forms of representation. A good example includes clustering, a process in which it compresses high dimensional data into 2D or 3D. It creates cartography by an optimization process to place data points with similar features closer and dissimilar ones farther. <u> Figure 1 </u> illustrates a map of emotions expressed through non-verbal vocalization. It seems impossible, and almost meaningless to try to understand analytically, the correlation between the expression of the vocalizer, the phenomenon of the vocalization manifested via sound, and the way it is perceived by the listener. Pay attention to the spaces in between the emotions. You can see that confusion and interest are placed in proximity to each other, and by probing the in-between areas, you can start to feel what elements of the sound are playing crucial roles in dividing the two. It is an entirely different approach than to reduce and to abstract the voice into separate parameters like frequency, timbre, or amplitude. You are able to have direct access to the brain circuit of the machine by interacting with this representation.
      </p>
      <div className="u-img-container">
        <img src = "https://static.wixstatic.com/media/05b268_d74ab5d5bc2f4cdcb4044e0188724c22~mv2.jpg/v1/fill/w_955,h_491,al_c,lg_1,q_85,enc_auto/05b268_d74ab5d5bc2f4cdcb4044e0188724c22~mv2.jpg"></img>
        <p className='u-caption '>
          Figure 1. Alan Cowen, Hillary Elfenbein, Petri Laukka, and Dacher Keltner. Mapping 24 Emotions Conveyed by Brief Human Vocalization. American Psychologist,
          December 20, 2018. <a href='https://s3-us-west-1.amazonaws.com/vocs/map.html' target='_blank'>link</a>
        </p>
      </div>
      <p>
        While supervised learning serves its purpose of classifying new data based on big data on which it is trained, <i>unsupervised learning</i> or <i>representation learning</i> is exciting in that it allows us to discover latent conceptual structures that we have not been able to realize. Standardized parts affect the way we perceive space. When we think of the concept of a "chair," it feels natural to think about it by dividing it into discrete elements such as legs that support the load, armrests that touch the body, and backrests, etc.
        Machine learning offers opportunities to blur the boundaries between discrete parts, and to discover means to look differently (<u>Figure 2</u>).
      </p>
      <div className="u-img-container u-no-shadow u-img-container-center">
        <img  src = "https://static.wixstatic.com/media/05b268_d3fb01d4587048ce99e13d5f653415c3~mv2.jpeg/v1/fill/w_712,h_638,al_c,q_85,enc_auto/Image%5B10212%5D.jpeg"></img>
        <p className='u-caption '>
          Figure 2. "What Makes a Chair Chair?"<br/>
          Ajay Jain, Ben Mildenhall, Jonathan T. Barron, Pieter Abbeel, Ben Poole. Zero-Shot Text-Guided Object Generation with Dream Fields. arXiv, 2021.
        </p>
      </div>
      <p>
        Ambiguity implies possibility.
        In quantum physics, uncertainty means a material's presence at a point signifies its highest probability of being there, while its presence elsewhere cannot be ruled out.
        Similarly, in design, the pre-materialization phase resembles a quantum cloud of probable alternatives. In machine learning, this is known as latent space.
        The ambiguity of latent space sparks imagination and curiosity, and encourages participation.
        Blurry and soft boundaries suggest that the boundaries can be easily morphed with the user's participation.
        Complexity, interspace, and gray areas create an "inquisitive body” by obscuring the affordance of things.
      </p>

      <h3>Beyond a Body</h3>
      <p>
        Availabler centers on an individual body. However, considering the objects we touch with our bodies, the area that our bodies can reach in our environment is extremely limited.
        Expanding our view to include invisible substances crossing our body's boundaries, what form would availabler take when accounting for the collective bodies beyond <i>me</i>?
      </p>
      <br/>
      <p className='u-grey'>
        <hr/>
        The text is translated into English by Kii Kang. The original text in Korean can be found here: <a target='_blank' href='https://www.forkingroom.kr/inger'>link</a>
      </p>
    </div>
    </div>
  )
}

export default P_Availabler;
