// import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter as Router, Route, Routes, Link
} from "react-router-dom";
// import Feed from "./pages/Feed";
import Info from "./pages/Info";
import P_BinauralKoreas from "./pages/p_BinauralKoreas";
import P_EmbodyingANarrative from "./pages/p_EmbodyingANarrative";
import P_Availabler from "./pages/p_Availabler";
import NavBar from "./modules/NavBar";
import CollabBar from "./modules/CollabBar";
import P_EOW from "./pages/p_EOW";
import P_Chimera from "./pages/p_Chimera";
import P_Thesis from "./pages/p_Thesis";
import P_LiveCoding from "./pages/p_LiveCoding";
import {useEffect, useState} from "react";

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        setIsMobile(isMobileDevice());
        if (isMobileDevice()) console.log("mobile device detected.")
    }, []);

    return (
      <Router>

            <div className="w-[100vw] h-[100vh] flex flex-col">
            <div>
                <Link to="/" className="flex cursor-pointer p-3 text-[rgb(0,0,207)]" key='link-name'>
                    Wonki (Kii) Kang / 康원기
                </Link>
            </div>
            <Routes>
                <Route path="/" element={
                    <div className="float-left flex flex-col justify-between w-auto h-full leading-snug">
                        <NavBar isMobile={isMobile} />
                        <CollabBar isMobile={isMobile} />
                    </div>
                } />
                <Route path="/info" element={<Info />} />
                <Route path="/binaural-koreas" element={<P_BinauralKoreas />} />
                <Route path="/embodying-a-narrative" element={<P_EmbodyingANarrative />} />
                <Route path="/availabler" element={<P_Availabler />} />
                <Route path="/experience-oriented-wayfinding" element={<P_EOW />} />
                <Route path="/chimera-place" element={<P_Chimera />} />
                <Route path="/attuning-to-hyperobjects" element={<P_Thesis />} />
                <Route path="/live-coding_act" element={<P_LiveCoding />} />
            </Routes>
            </div>
            </Router>
      // <div className="App-container">
      //     <Router>
      //     <NavBar />
      //     <CollabBar path="/" />
      //       <div className="App-contents">
      //           <Feed path="/" />
      //           <Info path="/info/" />
      //           <P_BinauralKoreas path="/binaural-koreas/" />
      //           <P_EmbodyingANarrative path="/embodying-a-narrative/" />
      //           <P_Availabler path="/availabler" />
      //           <NotFound default />
      //       </div>
      //     </Router>
      // </div>
  );
}

export default App;
