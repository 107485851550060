import './Projects.css'
const P_LiveCoding = () => {
    return (
        <div className="Project-container-c">
            <div className="Project-container">
                <div className='Project-header'>
                    Year: 2022<br/>
                    Domain: live coding, algorithmic composition, sound studies
                </div>
                <div className="u-media">
                <iframe src="https://www.youtube.com/embed/MYeup-3pxXo?si=gTppEaNm2yZeO2gh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <hr/><br/>
                <div className="u-grey">
Installation by Alejandro Medina and Cristóbal Garcia Belmont <br/>
Performance by Kii Kang (live coding, mixing) and Cristóbal Garcia Belmont (instruments, feedback loop) <br/>
Filmed by Weihan Jiang <br/>
Edited by Kii Kang <br/>
                </div>
            </div>
        </div>
    )
}
export default P_LiveCoding