import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";
import json from "./../media.json";

const NavBar = ({ isMobile }) => {
  const [projectHovered, setProjectHovered] = useState(null);
  const [preloadedImage, setPreloadedImage] = useState(null);
  const selectedWorks = json.filter(d => d.show);

  useEffect(() => {
    if (!projectHovered?.thumbnail) {
      setPreloadedImage(null);
      return;
    }

    const img = new Image();
    img.onload = () => setPreloadedImage(img);
    img.onerror = () => setPreloadedImage(null);
    img.src = "./media/" + projectHovered.thumbnail;
  }, [projectHovered]);

  const handleHoverStart = (d) => isMobile && setProjectHovered(d);
  const handleHoverEnd = () => setProjectHovered(false);

  const renderLink = (d) => {
    const props = {
      onTouchStart: () => handleHoverStart(d),
      onTouchEnd: handleHoverEnd,
      onMouseEnter: () => setProjectHovered(d),
      onMouseOut: handleHoverEnd,
      className: 'hover:opacity-0 m-0 p-0'
    };

    return d.url === "page" ? (
      <Link to={`/${d.id}`} {...props}>{d.title}<br/></Link>
    ) : (
      <a href={d.url} target="_blank" rel="noreferrer" {...props}>{d.title}<br/></a>
    );
  };

  return (
    <div className="text-lg left-0 relative leading-snug">
      <div className="p-[12px]">
        <Link to="/info/" className="flex">Info</Link>
        <div className="u-inlineBlock">
          <br/>
          <p>Selected Works</p>
          <br/>
          {selectedWorks.map(d => (
            <div key={d.title} className="w-fit h-fit m-0 p-0">
              {d.id === "attuning-to-hyperobjects" && (
                <><br/><br/>Selected Writings<br/><br/></>
              )}
              {renderLink(d)}
            </div>
          ))}
        </div>
      </div>
      <div className="fixed left-1/2 top-1/2 w-[80%] max-w-[1200px] h-[80%] transform -translate-x-1/2 -translate-y-1/2 inset-0 z-[9999] pointer-events-none">
        {projectHovered && preloadedImage && (
          <img 
            src={preloadedImage.src} 
            className="animate-[blur_0.5s_ease-in-out] w-full h-full object-contain"
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default NavBar;
