import React from "react";
import './NavBar.css';

const CollabBar = () => {
  return (
    <div className="relative bottom-0 left-0 text-[0.65rem] text-white p-[12px] leading-snug" key="NavBar-bottom" rel="noreferrer">
      <div key="NavBar-collab">
          <p className="text-sm">Collaborators</p>
      <a href="https://ivettakang.com/" target="_blank" rel="noreferrer" className="hover:text-black">
        Ivetta Sunyoung Kang</a> (2022-2024) <br />
      <a href="https://cristobalgarciabelmont.net/" target="_blank" rel="noreferrer" className="hover:text-black">
          Cristóbal Garcia Belmont </a> (2023) <br />
        Aidan Flynn (2022)<br />
      <a href="https://angiedoor.com/" target="_blank" rel="noreferrer" className="hover:text-black">Angie Door</a> (2022)<br />
      Alice Jia Li Song (2022)<br />
      <a href="https://kwanq.cargo.site/" target="_blank" rel="noreferrer" className="hover:text-black">
        Kwan Queenie Li</a> (2021-2022)<br />
      Weihan Jiang (2021)
      <br />
      <a href="https://www.meganprakash.com/" target="_blank" rel="noreferrer" className="hover:text-black">
        Megan Prakash</a> (2021)
      <br />
      Wuyahuang Li (2021)
      </div><br />
      <div>
        ---<br/><br/>
        Updated Jan, 2025
      </div>
    </div>
  )
}

export default CollabBar;
